import React from "react";
import { Col, Form, Row } from "react-bootstrap";

export function CursosDropdown({ onChange, cursos, fetchingCurso }) {
  return (
    <div>
      <h5 className="mt-3 mb-3">Selecione um Curso</h5>
      <Row>
        <Col md={6} lg={4}>
          <Form.Group controlId="cursoSelect" className="mb-3">
            <Form.Select size="l" onChange={onChange}>
              {fetchingCurso ? (
                <option>Carregando...</option>
              ) : (
                <>
                  <option value="">Selecione um curso...</option>
                  {cursos.map((curso) => (
                    <option key={curso.id} value={curso.id}>
                      {curso.title}
                    </option>
                  ))}
                </>
              )}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
}
