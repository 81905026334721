import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";

export function ModulesDropdown({ cursoId, onChange, cursos }) {
  const [modulos, setModulos] = useState([]);
  const [fetchingModulos, setFetchingModulos] = useState(false);

  useEffect(() => {
    if (cursoId && cursos && cursos.length > 0) {
      setFetchingModulos(true);
      const cursoSelecionado = cursos.find(
        (curso) => curso.id === parseInt(cursoId)
      );

      if (cursoSelecionado && cursoSelecionado.modules) {
        setModulos(cursoSelecionado.modules);
      } else {
        setModulos([]);
      }
      setFetchingModulos(false);
    } else {
      setModulos([]);
    }
  }, [cursoId, cursos]);

  return (
    <div>
      {cursoId && (
        <div>
          <h5 className="mt-3 mb-3">Selecione um Módulo</h5>
          <Row>
            <Col md={6} lg={4}>
              <Form.Group controlId="moduloSelect" className="mb-3">
                <Form.Select size="l" onChange={onChange}>
                  {fetchingModulos ? (
                    <option>Carregando...</option>
                  ) : (
                    <>
                      <option value="">Selecione um módulo...</option>
                      {modulos.map((modulo) => (
                        <option key={modulo.id} value={modulo.id}>
                          {modulo.title}
                        </option>
                      ))}
                    </>
                  )}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}
