import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../service";

function LoginForm() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const payload = JSON.parse(atob(token.split(".")[1]));
      const currentTime = Date.now() / 1000;
      if (payload.exp < currentTime) {
        navigate("/login");
      } else {
        navigate("/");
      }
    }
  }, [navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password === "" || username === "") {
      alert("Preencha os campos de email e senha!");
    } else {
      const data = { email: username, password: password };
      try {
        setLoading(true);
        const response = await api.post("/api/auth/login", data, {
          method: "post",
        });
        setLoading(false);
        localStorage.setItem("token", response.data.token);
        navigate("/");
      } catch (e) {
        setError(true);
        setPassword("");
      }
    }
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center vh-100">
      <img
        src="https://res.cloudinary.com/dbc0komav/image/upload/v1735597230/opih8lyidyjpvagqvdy7.png"
        alt="LogoCR"
        className="mb-2"
        width={100}
        height={100}
      />
      <form onSubmit={handleSubmit} className="p-4 border rounded-3">
        <div className="mb-3">
          <label htmlFor="username" className="form-label">
            Usuário:
          </label>
          <input
            type="text"
            id="username"
            className={`form-control ${error ? "is-invalid" : ""}`}
            value={username}
            required
            onChange={(event) => {
              setError(false);
              setUsername(event.target.value);
            }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Senha:
          </label>
          <input
            type="password"
            id="password"
            className={`form-control ${error ? "is-invalid" : ""}`}
            required
            value={password}
            onChange={(event) => {
              setError(false);
              setPassword(event.target.value);
            }}
          />
          {error && (
            <div className="invalid-feedback">Usuário ou Senha Inválidos</div>
          )}
        </div>

        <button type="submit" className="btn btn-primary">
          {loading ? "Carregando..." : "Entrar"}
        </button>
      </form>
    </div>
  );
}

export default LoginForm;
